import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Spacing, Text } from '@reservamos/elements';
import { fieldRenderer } from 'utils/formRenderers';
import { When } from 'react-if';
import getDocTypeRegex from 'utils/getDocTypeRegex';
import normalizeRegex from 'utils/normalizeRegex';
import { PhoneNumberField, GenderField, NationalityField } from '../../ReduxFields';
import normalizeName from '../../../utils/normalizeName';
import PassengerBusCategories from './PassengerBusCategories';
import DocumentTypeField from '../../DocumentTypeField';
import PassengerInsuranceCheckbox from '../../PassengerInsuranceCheckbox';
import normalizeEmail from '../../../utils/normalizeEmail';
import LabelSwitch from '../../../ui/atoms/LabelSwitch';
import InfoText from '../../../ui/atoms/InfoText';
import BirthDateField from '../../BirthDateField';
import FileSelectField from '../FileSelectField';
import { getDocumentTypeInstructioni18NKey } from '../../../utils/documentMaps';
import usePurchase from '../../../hooks/store/usePurchase';
import useLoyaltyPrograms from '../../../loyalty/context/useLoyaltyPrograms';
import { walletTypeNeedsUserInPassenger } from '../../../utils/loyalty';
import 'styles/components/PersonPassengersForm';

/**
 * A component representing a form for a person passenger information.
 * @param {Object} props - Object props
 * @param {boolean} props.autofillEnabled - Indicates whether autofill is enabled.
 * @param {array} props.busCategories - An array of available bus categories.
 * @param {boolean} props.disableFirstPassenger - Indicates whether the first passenger should be disabled.
 * @param {object} props.fields - The fields to be displayed in the form.
 * @param {function} props.handleHolderEditing - A function to handle editing the holder.
 * @param {boolean} props.holderEditingEnabled - Indicates whether the holder editing is enabled.
 * @param {number} props.index - The index of the current passenger in the passenger list.
 * @param {number} props.insuranceIncomingUnitAmount - The unit amount of incoming insurance.
 * @param {number} props.insuranceOutgoingUnitAmount - The unit amount of outgoing insurance.
 * @param {boolean} props.isExchange - Indicates whether it is an exchange passenger form.
 * @param {boolean} props.isFirstPassenger - Indicates whether it is the first passenger.
 * @param {boolean} props.isLogged - Indicates whether the user is logged in.
 * @param {string} props.passenger - The passenger information to be displayed in the form.
 * @param {object} props.passengerFields - The passenger fields wit the values to be displayed in the form.
 * @param {boolean} props.showLoyaltyHolder - Indicates whether to show the loyalty holder field.
 * @param {array} props.updatingCategories - An array of updating categories.
 * @param {string} props.loyaltyHolderName - The name of the loyalty holder.
 * @param {array} props.initialFields - The initial fields to be displayed in the form.
 * @param {boolean} props.isOpenTicket - Indicates whether it is an open ticket.
 * @returns {JSX.Element} A React component that renders a form for a single person's passenger information.
 */
const PersonPassengersForm = ({
  autofillEnabled,
  busCategories,
  fields,
  handleHolderEditing,
  holderEditingEnabled,
  index,
  insuranceIncomingUnitAmount,
  insuranceOutgoingUnitAmount,
  isExchange,
  isFirstPassenger,
  isLogged,
  passenger,
  passengerFields,
  showLoyaltyHolder,
  updatingCategories,
  isOpenTicket,
  loyaltyHolderName,
  initialFields,
}) => {
  const { t } = useTranslation('passengers');
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { availableWallets = [], walletType } = usePurchase();
  const accountShouldBePassenger = walletTypeNeedsUserInPassenger(walletType);
  const { userIsLoggedInWithAnyLoyaltyProgram } = useLoyaltyPrograms();
  const initialValues = initialFields.current ? initialFields.current[index] : passengerFields;
  const phoneCountry = initialValues?.phoneCountry || '';
  const isDisabled = useCallback(
    (field, name, isFirstPassenger) => {
      if (isExchange && !field && name === 'email') return false;

      /**
       * This condition is to disabled the fields when the purchase is an exchange,
       * Checks if the exchange should disable the passenger fields,
       * Checks if the initial values are set (only for exchanges),
       * Checks if the initial values are set for the current field, this is done to avoid the user change it,
       * If a field is not filled, the field is not disabled to be able to fill it. This can happen with terminal purchases.
       */
      const exchangeDisablingCondition = Boolean(
        isExchange && !features.EXCHANGE_PASSENGERS_EDITABLE && field,
      );
      /**
       * Fields are disabled if autofill is enabled, the phone field is not disabled because it is filled in this step.
       * Remember that the autofill function fills the fields with the first passenger data
       */
      const disableBecauseAutoFill = name !== 'phone' && autofillEnabled && isFirstPassenger;

      /**
       * The email field is not disabled if the email is not in passengers,
       * so the user can change the email even if the autofill is enabled
       */
      const enabledEmailField = name === 'email' && !features.SHOW_PASSENGERS_EMAIL_FIELD;

      // Disables the first passenger if the user is logged
      const loyaltyDisablingCondition =
        availableWallets?.includes(walletType) &&
        isFirstPassenger &&
        name !== 'busCategory' &&
        ((userIsLoggedInWithAnyLoyaltyProgram && accountShouldBePassenger) ||
          (!holderEditingEnabled && userIsLoggedInWithAnyLoyaltyProgram));

      return (
        exchangeDisablingCondition ||
        disableBecauseAutoFill ||
        enabledEmailField ||
        loyaltyDisablingCondition
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      holderEditingEnabled,
      isExchange,
      isLogged,
      userIsLoggedInWithAnyLoyaltyProgram,
      availableWallets,
    ],
  );

  const canUseEditSwitch = {
    costapass: false,
    doters: false,
    siempreplus: true,
  };

  /**
   * Check if the field should be visible based on the
   * feature flags and the passenger category.
   *
   * If the feature flag specified by fieldPerPassenger is not enabled,
   * the field should not be visible.
   *
   * If the feature flag specified by showFieldExcludingAdults is
   * enabled, the field should not be visible if the passenger selected the general category.
   *
   * @param {boolean} fieldPerPassenger - Indicates if the field is per passenger.
   * @param {boolean} showFieldExcludingAdults - Indicates if the field should be shown excluding adults.
   * @returns {boolean} True if the field should be visible, false otherwise.
   */
  const isFieldAllowedForCategory = (fieldPerPassenger, showFieldExcludingAdults) => {
    if (!fieldPerPassenger) return false;

    if (showFieldExcludingAdults) {
      return !passengerFields.busCategory.includes('general');
    }

    return true;
  };

  /**
   * Render the birthdate fields if either the
   * birth date per passenger or the nationality per passenger
   * feature flags are enabled or not.
   *
   * @returns {JSX.Element} The birth date fields
   */
  const renderBirthDateFields = () => (
    <When condition={features.BIRTH_DATE_PER_PASSENGER || features.NATIONALITY_PER_PASSENGER}>
      <Spacing
        size="S"
        flexGrow
        isResponsive
        alignItems="baseline"
        mobileAlign="inherit"
        responsiveColumnReverse
      >
        <When
          condition={isFieldAllowedForCategory(
            features.BIRTH_DATE_PER_PASSENGER,
            features.SHOW_BIRTH_DATE_EXCLUDING_ADULTS,
          )}
        >
          <Field
            id={`${passenger}.dateOfBirth`}
            name={`${passenger}.dateOfBirth`}
            passengerType={passengerFields.category}
            component={BirthDateField}
          />
        </When>
        <When condition={features.NATIONALITY_PER_PASSENGER}>
          <NationalityField name={`${passenger}.nationality`} />
        </When>
        <When condition={!features.BIRTH_DATE_PER_PASSENGER || !features.NATIONALITY_PER_PASSENGER}>
          <div />
        </When>
      </Spacing>
    </When>
  );

  /**
   * Render the file list field, and add spacing around it.
   *
   * @returns {JSX.Element} The file list field.
   */
  const renderFileSelectField = () => {
    const dividerStyles = {
      width: '100%',
      height: '17px',
    };

    return (
      <>
        <div style={dividerStyles} />
        <Spacing
          size="M"
          flexGrow
          isResponsive
          alignItems="baseline"
          mobileAlign="inherit"
          responsiveColumnReverse
        >
          <Field
            name={`${passenger}.document`}
            id={`${passenger}.document`}
            type="file"
            passengerType={passengerFields.category}
            placeholder={t('choose_file')}
            component={FileSelectField}
          />
        </Spacing>
        <div style={dividerStyles} />
      </>
    );
  };

  const exchangeInsuranceValidation =
    !isExchange || (isExchange && features.CAN_ADD_EXCHANGE_INSURANCE);

  const showEditNameSwitch =
    showLoyaltyHolder && !isExchange && canUseEditSwitch[loyaltyHolderName] && isFirstPassenger;

  const showSecondFirstName = features.SECOND_FIRST_NAME_PER_PASSENGER;
  const docTypeRegex = getDocTypeRegex('passengers', passengerFields.documentType);

  return (
    <>
      <div className="person-passengers-form">
        <Field
          autoComplete="given-name"
          name={`${passenger}.firstName`}
          id={`${passenger}.firstName`}
          type="text"
          placeholder={showSecondFirstName ? t('first_fore_name') : t('fore_name')}
          component={fieldRenderer}
          normalize={normalizeName}
          isDisabled={isDisabled(initialValues.firstName, 'firstName', isFirstPassenger)}
        />
        <When condition={showSecondFirstName}>
          <Field
            autoComplete="second-first-name"
            name={`${passenger}.secondFirstName`}
            id={`${passenger}.secondFirstName`}
            type="text"
            placeholder={`${t('second_fore_name')} ${t('optional')}`}
            component={fieldRenderer}
            normalize={normalizeName}
            isDisabled={isDisabled(
              initialValues.secondFirstName,
              'secondFirstName',
              isFirstPassenger,
            )}
          />
        </When>
        <Field
          autoComplete="family-name"
          name={`${passenger}.lastName`}
          id={`${passenger}.lastName`}
          type="text"
          placeholder={t('family_name_one')}
          component={fieldRenderer}
          normalize={normalizeName}
          isDisabled={isDisabled(initialValues.lastName, 'lastName', isFirstPassenger)}
        />
        {features.PASSENGERS_EXTRA_FIELDS && (
          <Field
            autoComplete="do-not-autofill"
            name={`${passenger}.secondLastName`}
            id={`${passenger}.secondLastName`}
            type="text"
            placeholder={`${t('family_name_two')} ${t('optional')}`}
            component={fieldRenderer}
            normalize={normalizeName}
            isDisabled={isDisabled(
              initialValues.secondLastName,
              'secondLastName',
              isFirstPassenger,
            )}
          />
        )}
      </div>

      {(features.PASSENGERS_EXTRA_FIELDS || features.SHOW_PASSENGERS_EMAIL_FIELD) && (
        <Spacing size="S" flexGrow isResponsive>
          <Field
            autoComplete="email"
            name={`${passenger}.email`}
            id={`${passenger}.email`}
            type="text"
            placeholder={`${t('email')}${index !== 0 ? ` ${t('optional')}` : ''}`}
            normalize={normalizeEmail}
            component={fieldRenderer}
            isDisabled={isDisabled(initialValues.email, 'email', isFirstPassenger)}
          />
        </Spacing>
      )}

      {!features.SHOW_BIRTH_DATE_BELOW_BUS_CATEGORIES && renderBirthDateFields()}

      {showEditNameSwitch && (
        <LabelSwitch
          label={t('edit_name')}
          justifyContent="flex-end"
          checked={holderEditingEnabled}
          onChange={handleHolderEditing}
        />
      )}

      {features.IDENTIFICATION_DOCUMENT_PER_PASSENGER && (
        <Spacing size="S" flexGrow>
          <DocumentTypeField
            id={`${passenger}.documentType`}
            name={`${passenger}.documentType`}
            cls="form-item-wrap-l"
            passengerNationality={passengerFields.nationality}
            passengerDocumentType={passengerFields.documentType}
            index={index}
          />
          <div className="form-item-wrap-r">
            <Spacing size="S" vertical>
              <Field
                id={`${passenger}.documentId`}
                name={`${passenger}.documentId`}
                type="text"
                placeholder={t('document_number')}
                normalize={(input) => normalizeRegex(docTypeRegex, input)}
                component={fieldRenderer}
              />
              {getDocumentTypeInstructioni18NKey(passengerFields.documentType) ? (
                <Text size="XS" color="accent">
                  {t(getDocumentTypeInstructioni18NKey(passengerFields.documentType))}
                </Text>
              ) : null}
            </Spacing>
          </div>
        </Spacing>
      )}

      {(features.PHONE_NUMBER_PER_PASSENGER || features.GENDER_PER_PASSENGER) && (
        <Spacing size="S" flexGrow isResponsive>
          <div className="person-passengers-form">
            <When condition={features.PHONE_NUMBER_PER_PASSENGER}>
              <PhoneNumberField
                autoComplete="tel-national"
                isDisabled={isDisabled('phone')}
                phoneCountryFieldName={`${passenger}.phoneCountry`}
                phoneCodeFieldName={`${passenger}.phoneCode`}
                phoneFieldName={`${passenger}.phone`}
                formName="passengers"
                placeholder={t('phone')}
                cls="form-item-wrap-l"
                initCountry={phoneCountry}
              />
            </When>
            <When condition={features.GENDER_PER_PASSENGER}>
              <GenderField name={`${passenger}.gender`} />
            </When>
          </div>
        </Spacing>
      )}

      <PassengerBusCategories
        name={`${passenger}.busCategory`}
        passengerCategory={passengerFields.category}
        isFirstPassenger={isFirstPassenger}
        busCategories={busCategories}
        isUpdating={updatingCategories}
        isDisabled={isDisabled(initialValues.busCategory, 'busCategory', isFirstPassenger)}
        isExchange={isExchange}
        passengerBusCategory={passengerFields.busCategory}
        isOpenTicket={isOpenTicket}
        initialBusCategory={initialValues.busCategory}
      />

      {features.SHOW_BIRTH_DATE_BELOW_BUS_CATEGORIES && renderBirthDateFields()}

      {isFieldAllowedForCategory(
        features.DOCUMENT_PER_PASSENGER,
        features.SHOW_DOCUMENT_EXCLUDING_ADULTS,
      ) && renderFileSelectField()}

      {loyaltyHolderName &&
        holderEditingEnabled &&
        isFirstPassenger &&
        !walletTypeNeedsUserInPassenger(loyaltyHolderName) && (
          <InfoText>{t(`${loyaltyHolderName}_disclaimer`)}</InfoText>
        )}

      {exchangeInsuranceValidation &&
        features.PASSENGERS_INSURANCE_ENABLED &&
        (insuranceIncomingUnitAmount > 0 || insuranceOutgoingUnitAmount > 0) && (
          <PassengerInsuranceCheckbox
            passenger={passenger}
            insuranceOutgoingUnitAmount={insuranceOutgoingUnitAmount}
            insuranceIncomingUnitAmount={insuranceIncomingUnitAmount}
            isExchange={isExchange}
            wantsOutgoingInsurance={isExchange && fields.get(index).wantsOutgoingInsurance}
            wantsIncomingInsurance={isExchange && fields.get(index).wantsIncomingInsurance}
          />
        )}
    </>
  );
};

PersonPassengersForm.propTypes = {
  autofillEnabled: PropTypes.bool.isRequired,
  busCategories: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleHolderEditing: PropTypes.func.isRequired,
  holderEditingEnabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  insuranceIncomingUnitAmount: PropTypes.number.isRequired,
  insuranceOutgoingUnitAmount: PropTypes.number.isRequired,
  isExchange: PropTypes.bool.isRequired,
  isFirstPassenger: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  passenger: PropTypes.object.isRequired,
  passengerFields: PropTypes.object.isRequired,
  showLoyaltyHolder: PropTypes.object.isRequired,
  updatingCategories: PropTypes.bool.isRequired,
  isOpenTicket: PropTypes.bool,
  loyaltyHolderName: PropTypes.string,
  initialFields: PropTypes.array,
};

PersonPassengersForm.defaultProps = {};

export default PersonPassengersForm;
