/**
 * For more details, refer to the documentation: [https://www.notion.so/reservamos/Feature-Flags-75c6bb137ef2416ca8e36f03fd042fca?pvs=4]
 */

const defaultFeatures = {
  BILLING_ADDRESS_ENABLED: false,
  CALENDAR_RANGE: 90,
  DISCOUNT_CODE_ENABLED: false,
  FUNNEL_STYLE: 'CLASSIC', // CLASSIC | FLAT
  IDENTIFICATION_DOCUMENT_PER_PASSENGER: false,
  IDENTIFICATION_DOCUMENT_FOR_PURCHASER: false,
  INSTALLMENTS_ENABLED: false,
  LOADING_TYPE: 'DEFAULT', // DEFAULT | DOTS
  LODGING_CHECKBOX_ENABLED: false,
  PASSENGERS_INSURANCE_ENABLED: false,
  PASSENGERS_TERMS_AND_CONDITIONS_ENABLED: false,
  PURCHASE_TIMER_ENABLED: true,
  PURCHASER_FORM_ON: 'PASSENGERS', // PASSENGERS | CHECKOUT
  PRICE_CHANGED_MODAL_ENABLED: false,
  PRINT_TICKETS_REQUIRED: true,
  PRIVACY_BANNER_ENABLED: false,
  PROVIDERS_SELECTION_ENABLED: true,
  OPEN_TICKET_PROVIDER_SELECTION_ENABLED: false,
  SEARCH_SIDEBAR_ENABLED: false,
  SEAT_SELECTION_LIMIT: 5,
  SHOW_RECOMENDATIONS: false,
  SHOW_SERVICE_TYPE: true,
  SUPPORT_FOR_ORTHOGRAPHIC_ACCENTS_ON_PASSENGERS: true,
  STORE_PURCHASER_FORM: true,
  STORE_PASSENGERS_FORM: true,
  SHOW_PURCHASER_EMAIL_FIELD: true,
  SHOW_PURCHASER_SECOND_LAST_NAME_FIELD: false,
  SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM: true,
  LOW_AVAILABILITY_THRESHOLD: 5,
  VALID_CATEGORIES: [],
  COUPON_ON_SEARCH: false,
  USE_HORIZONTAL_RESULTS: false,
  SEARCH_CITY_REDUNDANT: false,
  SHOW_HEADER_LABEL: true,
  MULTI_OPEN_TICKETS: false,
  NEW_PROVIDER_RESULTS: false,
  SCHEDULE_RESULT_TYPE: 'default', // default | specifics | gfa
  SHOW_OPEN_TICKET_ON_PROVIDERS: false,
  SHOW_SEARCH_FOOTER: false,
  SHOW_PURCHASE_FOOTER: true,
  SHOW_PURCHASE_REVIEW_TABS: false,
  SHOW_SIDEBAR_MENU: false,
  BIRTH_DATE_FIELDS_FORMAT: 'YYYY-MM-DD', // YYYY-MM-DD | DD-MM-YYYY
  ALLOW_TO_CHANGE_LANGUAGE: false,
  ALLOW_TO_SAVE_CARDS: false,
  USE_LEGACY_LOCK_TICKETS: false,
  PASSENGERS_EXTRA_FIELDS: false,
  PASSENGERS_SHOW_ID: false,
  VALIDATE_ROUND_TRIP_DISCOUNT: false,
  TRIP_PRICING_BY_PASSENGER: true,
  SHOW_MIN_PRICES: false,
  SHOW_TICKET_PRICE_WITHOUT_TAX: false,
  TRIP_REDIRECTION_LEAD: false,
  TRIP_REDIRECTION_RECEIVE: false,
  SHOW_SCHEDULE_CHANGES_TEXT: false,
  SHOW_SORT_PROPERTY: false,
  SHOW_OPEN_TICKET_ON_RESULTS: false,
  OPEN_TICKET_MODAL_ENABLED: false,
  SHOW_DYNAMIC_PROMOTIONS: false,
  GFA_INSURANCE_CHECKBOX: false,
  SHOW_ADYEN_CARD_LOGOS: true,
  PERSIST_SEATS_ON_RECEIVE_PURCHASE: false,
  WOMAN_SEATS_ENABLED: false,
  RETURN_TRIP_REMINDER: false,
  PURCHASE_PRICING_AGGREGATION_ENABLED: false,
  PURCHASE_PRICING_DETAILED_DISCOUNT: false,
  SORT_TRIPS_BY_PRICE: false,
  SHOW_PASSENGERS_SECOND_LAST_NAME_FIELD: true,
  SHOW_PASSENGERS_EMAIL_FIELD: true,
  CAN_USE_POINTS: false,
  ALLOW_TO_EXCHANGE_TICKETS: false,
  USE_NERBY_TERMINALS: false,
  EXCHANGE_PASSENGERS_EDITABLE: false,
  CAN_ADD_EXCHANGE_INSURANCE: false,
  CAN_EXCHANGE_CHANGE_PLACES: false,
  OPENPAY_REGION: 'mexico',
  CAN_EXCHANGE_OPEN_TICKET: false,
  VALIDATE_LOYALTY_ON_EXCHANGE: [],
  DISABLE_EXCHANGE_PURCHASER: false,
  SHOW_SERVICE_TYPE_BADGE: false,
  SHOW_TRIP_DETAILS_AT_RESULTS: false,
  SHOW_DISCOUNTS_MODAL_AT_RESULTS: false,
  DOWNLOAD_TICKETS_PAGE_ENABLED: false,
  REMOTE_TICKETS: false,
  BIRTH_DATE_PER_PASSENGER: false,
  NATIONALITY_PER_PASSENGER: false,
  DOCUMENT_PER_PASSENGER: false, // Flag that indicates if the document field should be shown per passenger
  TICKETS_REFERENCE: 'transporter-key', // transporter-key | purchase-token
  SHOW_ITINERARY_AT_TRIP_DETAILS: true,
  SHOW_BIRTH_DATE_BELOW_BUS_CATEGORIES: false,
  SHOW_BIRTH_DATE_EXCLUDING_ADULTS: false,
  SHOW_BIRTH_DATE_WARNING: false, // Flag that indicates if the birth date warning should be shown
  SHOW_DOCUMENT_EXCLUDING_ADULTS: false, // Flag that indicates if the document field should be shown excluding adults
  SHOW_PASSENGER_IS_BUYER: false,
  PHONE_COUNTRY_SAVED_BACKEND: false,
  NEEDS_PASSENGER_EMAIL_ON_PURCHASER_PAYLOAD: false, // Flag that indicates that the email should be send to the purchaser data even if it is not showed in the purchaser form
  JUST_ADULT_OPEN_TICKET: false,
  SHOW_OPEN_TICKET_RESULT: false,
  COSTAPASS_ENABLED: false,
  DOTERS_ENABLED: false,
  USE_SSO: false,
  AMENITIES_FIRST_AT_TRIP_DETAILS: false,
  PRICING_BEFORE_CHECKOUT: false,
  DISABLE_PASSENGER_LOGGED: false,
  SPECIAL_CATEGORIES: [],
  LOYALTY_NEEDS_ACCOUNT_IN_PURCHASER: [],
  LOYALTY_NEEDS_ACCOUNT_IN_PASSENGERS: [],
  POLICIES_DISCLAIMER_LOCATION: 'PASSENGERS', // PASSENGERS | CHECKOUT
  SHOW_POWERED_BY: true,
  ROUND_CURRENCY_AMOUNTS: true,
  TOTAL_AT_THE_BOTTOM: false,
  /**
   * Array of object to use as filter. This allows costapass filter
   * @example:
   * {
   *  id: 'costapass',
   *  i18Key: 'search:trip_options.costapass',
   *  primaryText: 'search:trip_options.costapass',
   *   value: {
   *     availableWallets: 'costapass',
   *   },
   * },
   */
  TRIP_OPTIONS_FILTER: [],
  BOOKING_REVIEW_ENABLED: false,
  DYNAMIC_CYBERSOURCE_CREDENTIALS: false,
  SHOW_OPEN_TICKET_BUTTON: true,
  DISCOUNT_LABEL_ROUND_METHOD_ON_RETURN: 'ROUND', // ROUND | FLOOR | CEIL
  SHOW_STOPS_FILTER: true,
  DEFAULT_PAYMENT_OPTION_FIRST: false, // If true, the default payment option will be the first one
  USE_TERMINAL_NAME_WITH_LOCATION: false,
  SHOW_OFFICE_PRICE: true,
  EXTRA_RESULT_LINES: [], // Bus lines that owns a route
  SHOW_SECOND_INFANT_DISCLAIMER: false, // If true, the second infant disclaimer will be shown
  SHOW_CREDIT_DEBIT_LOGOS: false,
  SHOW_INSTALLMENTS_BANKS: true,
  SHOW_DISCOUNT_BANNER: false,
  VISIBLE_DISCOUNTS_FOR_RESULTS: [],
  CHANGE_RESULT_LAYOUT: false,
  RESULT_SHADOW_VARIANT: false,
  RECOMMENDED_TRIPS_ENABLED: false,
  RECOMMENDED_TRIPS_LIMIT: 3, // Number of recommended trips to show
  SHOW_DIGITAL_DISCOUNT_BANNER: false,
  SHOW_DISCOUNTS_ON_RESULTS_DETAILS: false,
  HAS_MULTIPLE_FLOORS_TRIPS: false,
  PQRS_FORM: false, // If true, the PQRS form for Ochoa will be available
  CAN_SWITCH_WALLET_PRICE: false,
  TERMINAL_INCLUDES_CITY_NAME: false,
  SHOW_RESULT_LINE_NAME: true,
  SHOW_VARIABLE_DEPARTURE_TIME: false,
  FLAT_FARE_CONTEXT: 'boleto_recompensa', // boleto_recompensa | tarifa_exclusiva
  DOTERS_AUTOMATIC_ENROLL: false,
  SHOW_ACCEPT_POLICIES_CHECKBOX: false, // If true, the accept policies checkbox will be shown
  NATIONALITY_FIELD_WITH_FILTER: false, // If true, the nationality field will be shown with a filter
  PHONE_NUMBER_PER_PASSENGER: false, // If true, the phone number field will be shown per passenger
  GENDER_PER_PASSENGER: false, // If true, the gender field will be shown per passenger
  SEATS_ON_RESULTS: false, // Enables the flow where the seats are selected on the results page
  SECOND_FIRST_NAME_PER_PASSENGER: false, // If true, the second first name field will be shown per passenger
  HOUR_FORMAT: '12', // 12 | 24
  SUPPORT_TRIPS_WITH_CONNECTIONS: false,
  ACCEPT_PURCHASER_BUSINESS_NAME: false,
  DOTERS_FLATFARE_VALIDATE_ROUND_TRIP: true, // If true, the round trip discount will be validated on return trip
  SHOW_TRIP_SERVICE_LABEL: false, // If true, the service will be shown in the results as comes from the backend
  ALLOW_CHANGE_CATEGORY_ON_EXCHANGE: false, // If true, the category can be changed on exchange
  SHOW_INSTALLMENTS_INTEREST: false, // If true, the interest will be shown in the installments
  SHOW_TRIP_DETAIL_CARD: false, // If true shows detail card on purchase sidebar for non flat-style tennats
  SHOW_TICKETS_FROM_FRAGMENTS: false, // If true, it calculates and shows tickets from departure/return fragments
  PRICE_ALERTS_ENABLED: false, // If true, the price alerts feature will be enabled
};

export default defaultFeatures;
