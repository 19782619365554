import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FieldGroup, Spacing } from '@reservamos/elements';
import BirthDateFieldItem from './BirthDateFieldItem';

/**
 * BirthDateField component.
 * @param {Object} props - The component props.
 * @param {string} props.passengerType - The type of passenger (adult, child, infant).
 * @param {Object} props.input - The input props from redux-form.
 * @param {Object} props.meta - The meta props from redux-form.
 * @returns {JSX.Element} The rendered component.
 */
const BirthDateField = ({ passengerType, input, meta }) => {
  const { t } = useTranslation('passengers');
  const dateArray = input.value.split('-');
  const year = dateArray[0] ?? '';
  const month = dateArray[1] ?? '';
  const day = dateArray[2] ?? '';

  /**
   * Handle date change.
   * @param {string} type - The type of date part (day, month, year).
   * @param {string} value - The new value.
   */
  function handleDateChange(type, value) {
    let newDate;

    switch (type) {
      case 'day':
        newDate = `${year}-${month}-${value}`;
        break;
      case 'month':
        newDate = `${year}-${value}-${day}`;
        break;
      case 'year':
        newDate = `${value}-${month}-${day}`;
        break;
      default:
    }

    input.onChange(newDate);
  }

  /**
   * Render the birth date fields, depending on the birth date
   * format specified by the feature flag.
   * @returns {JSX.Element} The rendered component.
   */
  function renderBirthDateFields() {
    return (
      <BirthDateFieldItem
        year={year}
        month={month}
        day={day}
        passengerType={passengerType}
        meta={meta}
        onDateChange={(type, value) => handleDateChange(type, value)}
        primaryFieldType="day"
        middleFieldType="month"
        lastFieldType="year"
      />
    );
  }

  return (
    <>
      <FieldGroup label={t('birth_date')}>
        <Spacing isResponsive={false} size="XS" flexGrow>
          {renderBirthDateFields()}
        </Spacing>
      </FieldGroup>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Field {...input} type="hidden" component="input" />
    </>
  );
};

BirthDateField.propTypes = {
  passengerType: PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
  // Redux-form props
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default BirthDateField;
