import React, { Fragment } from 'react';
import moment from 'moment';
import { When } from 'react-if';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Icon } from '@reservamos/elements';
import SendEmail from 'components/purchase/SendEmail';
import PaymentStatusIcon from 'components/purchase/PaymentStatusIcon';
import PaymentStepStatus from 'components/purchase/PaymentStepStatus';
import TicketSummary from 'ui/molecules/TicketSummary';
import PurchaseToken from 'ui/atoms/PurchaseToken';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import usePurchase from 'hooks/store/usePurchase';
import usePayment from 'hooks/store/usePayment';
import { generateGoogleMapsSearchUrl } from 'utils/urls';
import parsePurchaseStatus from 'utils/parsePurchaseStatus';
import emojiParty from 'images/icons/emoji-party.png';
import 'styles/components/purchase/PurchasesLayout';
import wayIsOpenTicket from 'utils/wayIsOpenTicket';

const getTicketSummaryParams = (tripFragment) => {
  const originMapsUrl = generateGoogleMapsSearchUrl({
    lat: tripFragment.origin.coordinates.lat,
    long: tripFragment.origin.coordinates.long,
  });
  const destinationMapsUrl = generateGoogleMapsSearchUrl({
    lat: tripFragment.destination.coordinates.lat,
    long: tripFragment.destination.coordinates.long,
  });
  return {
    originTime: moment(tripFragment.departure).format('LT'),
    originTerminal: tripFragment.origin.name,
    originCity: tripFragment.origin.cityName,
    originLocation: originMapsUrl,
    destinationTime: moment(tripFragment.arrival).format('LT'),
    destinationTerminal: tripFragment.destination.name,
    destinationCity: tripFragment.destination.cityName,
    destinationLocation: destinationMapsUrl,
    href: tripFragment.ticketPdfUrl,
  };
};

const DownloadTicketsSection = () => {
  const { t } = useTranslation('purchase');
  const { token, state: purchaseStatus, email, departs, returns } = usePurchase();
  const { paymentType } = usePayment();
  const status = parsePurchaseStatus(purchaseStatus, paymentType);
  const { brand } = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();
  const displayPurchaseToken = features.TICKETS_REFERENCE === 'purchase-token';
  const tenantUsesRemoteTickets = features.REMOTE_TICKETS;
  const displayDownloadTicketsButton = !tenantUsesRemoteTickets;
  const departureFragments = departs.fragments || [];
  const returnFragments = returns.fragments || [];
  const departureIsOpenTicket = wayIsOpenTicket(departs);
  const returnIsOpenTicket = wayIsOpenTicket(returns);
  const departureDate = moment(departs.departure).format('DD MMM');
  const returnDate = returns.departure ? moment(returns.departure).format('DD MMM') : '';

  const renderTicketsSummaryTitle = ({ title, date }) => {
    return (
      <Text size="L" weight="bold">
        {`${title} - ${date}`}
      </Text>
    );
  };

  const renderTicketsSummary = (fragments) => {
    return fragments.map((fragment) => {
      const fragmentTickets = fragment.tickets || [];
      const fragmentHasTickets = fragmentTickets.length > 0;

      if (!fragmentHasTickets) {
        return null;
      }

      const ticketSummaryParams = getTicketSummaryParams(fragment);
      return (
        <Fragment key={ticketSummaryParams.href}>
          <TicketSummary
            {...ticketSummaryParams}
            buttonLabel={t(!fragment.openTicket ? 'get_ticket' : 'get_ticket_open')}
          />
          <When condition={displayPurchaseToken}>
            <Text size="S">
              {`${t('purchase_reference')} `}
              <Text size="S" elementType="span" weight="bold">
                {fragment.transporterKey}
              </Text>
            </Text>
          </When>
        </Fragment>
      );
    });
  };

  return (
    <Spacing vertical size="XXL">
      <div className="purchase-layout-content-wrapper">
        <Spacing vertical>
          <Spacing vertical size="XS">
            <Text weight="bold" size="XL">
              {t('label.tickets_ready')}
              <Icon type={emojiParty} hasMargin marginSide="left" />
            </Text>
            <Text color="grayMedium">{t('ticket_instructions_digital', { context: brand })}</Text>
          </Spacing>
          {token && <PurchaseToken token={token} />}
        </Spacing>
        <PaymentStatusIcon />
      </div>

      <When condition={displayDownloadTicketsButton}>
        <Spacing vertical>
          {renderTicketsSummaryTitle({
            title: t(!departureIsOpenTicket ? 'ticket_departure' : 'ticket_departure_open'),
            date: departureDate,
          })}
          {renderTicketsSummary(departureFragments)}
          <When condition={returnDate}>
            {renderTicketsSummaryTitle({
              title: t(!returnIsOpenTicket ? 'ticket_return' : 'ticket_return_open'),
              date: returnDate,
            })}
          </When>
          {renderTicketsSummary(returnFragments)}
        </Spacing>
      </When>
      <Spacing flexGrow isResponsive>
        <Spacing vertical size="S">
          <PaymentStepStatus
            transporterType="bus"
            status={status.sent}
            title="sent"
            email={email}
          />
          <Text size="S">{t('payment:text.check_spam_email')}</Text>
        </Spacing>
        <SendEmail />
      </Spacing>
    </Spacing>
  );
};

export default DownloadTicketsSection;
